<template>
  <a-layout>
    <a-layout-header style="background: #fff; padding: 0"/>
    <a-layout-content style="margin: 0 16px">
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>管理员列表</a-breadcrumb-item>
      </a-breadcrumb>
      <!--            <div class="toolsbar">-->
      <!--                <div style="float: left;margin-left: 20px;margin-top: 10px;">-->
      <!--                    <a-button type="primary" @click="addadmin()">新建管理员</a-button>-->
      <!--                    <a-button type="primary" :loading="loading" @click="start" style="margin-left: 20px">-->
      <!--                        刷新-->
      <!--                    </a-button>-->
      <!--                </div>-->
      <!--                <div class="serach_input">-->
      <!--                    <a-input-group compact>-->
      <!--                        <a-select defaultValue="用户名">-->
      <!--                            <a-select-option value="username">用户名</a-select-option>-->
      <!--                            &lt;!&ndash;                    <a-select-option value="username">用户名</a-select-option>&ndash;&gt;-->
      <!--                        </a-select>-->
      <!--                        <a-input-search style="width: 64%" defaultValue @search="onSearch" enterButton/>-->
      <!--                    </a-input-group>-->
      <!--                </div>-->
      <!--            </div>-->
      <a-row>
        <a-col :push="4" :span="20">
          <div v-show="!Section_show" :style="{ padding: '24px', background: '#fff', minHeight: '650px' }">
            <a-result title="请选择部门">

            </a-result>
          </div>
          <div v-show="Section_show" :style="{ padding: '24px', background: '#fff', minHeight: '650px' }">
            <a-row justify="space-between" type="flex">
              <a-col :span="4">
                <h1>部门名称: {{ Section_name }}</h1>
              </a-col>
              <a-col :span="12" style="text-align:right;margin-bottom:10px">
                <a-button style="margin-right: 10px" type="primary" @click="addadmin">
                  新建用户
                </a-button>
                <a-button style="margin-right: 10px" type="primary" @click="addSectionchildren(Section_id)">
                  新增下级部门
                </a-button>
                <a-button style="margin-right: 10px" type="primary" @click="editSection(Section_id)">
                  编辑部门
                </a-button>
                <a-button style="margin-right: 0px" type="primary" @click="delSection(Section_id)">
                  剔除部门
                </a-button>

              </a-col>

            </a-row>
            <!--                                {{data}}-->

            <a-table
                :columns="columns"
                :dataSource="SectionuserData"
                :default-selected-keys=Section_id
                :loading="loading"
                :pagination="pagination"
                bordered
                rowKey="uid"
            >

              <template slot="status" slot-scope="text, index" style="text-align: center">
                <a-tag v-if="index.status === 1" :color="'green'"><span>正常</span></a-tag>
                <a-tag v-if="index.status !== 1" :color="'red'">封停</a-tag>
              </template>


              <template slot="tags" slot-scope="text, index" style="text-align: center">
                <a-tag v-if="index.is_admin === 1" :color="'green'"><span>后台管理员</span></a-tag>
                <a-tag v-if="index.is_student === 1" :color="'blue'">学员/讲师</a-tag>
                <a-tag v-if="index.is_teacher === 1" :color="'cyan'">工作人员</a-tag>
              </template>

              <template slot="operation" slot-scope="text, index">
                            <span style="margin-right: 5px;">
                              <a @click="editadmin(index)">编辑</a>
                            </span>
                <span>
                              <a @click="deladmin(index)">剔除</a>
                            </span>
              </template>

            </a-table>
          </div>
        </a-col>
        <a-col :pull="20" :span="4">
          <div :style="{ padding: '24px', background: '#fff', minHeight: '650px' }">
            <a-button block type="primary" @click="addSection()">
              新建部门
            </a-button>
            <a-tree :expandedKeys.sync="expandedKeys" :tree-data="treeData" showLine @select="onContextMenuClick">
            </a-tree>
          </div>
        </a-col>
      </a-row>

    </a-layout-content>
    <a-layout-footer style="text-align: center"></a-layout-footer>
    <a-modal v-model="pop_Sectionadd" title="新增部门" width="45%" @ok="Section_addok">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="部门名称">
        <a-input v-model="add_Section.name"/>
      </a-form-item>
    </a-modal>
    <a-modal v-model="pop_Sectionedit" title="编辑部门" width="45%" @ok="Section_editok">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="部门名称">
        <a-input v-model="edit_Section.name"/>
      </a-form-item>
    </a-modal>

    <a-modal v-model="pop_Sectionchildrenadd" title="新增子部门" width="45%" @ok="Section_childrenadd">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="部门名称">
        <a-input v-model="add_Section.name"/>
      </a-form-item>
    </a-modal>
    <a-modal v-model="pop_Useradd" title="新增用户" width="45%" @ok="User_addok">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="登录名">
        <a-input v-model="add_User.login_name"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="姓名">
        <a-input v-model="add_User.name"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="手机号码">
        <a-input v-model="add_User.mobile"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="性别">
        <a-input v-model="add_User.sex"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="邮箱">
        <a-input v-model="add_User.email"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="头像">
        <a-input v-model="add_User.photo"/>
      </a-form-item>
    </a-modal>
    <a-modal v-model="pop_Useredit" title="编辑用户" width="45%" @ok="User_editok">
<!--      {{ edit_User }}-->
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="登录名">
        <a-input v-model="edit_User.login_name"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="姓名">
        <a-input v-model="edit_User.name"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="手机号码">
        <a-input v-model="edit_User.mobile"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="性别">
        <a-input v-model="edit_User.sex"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="邮箱">
        <a-input v-model="edit_User.email"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="头像">
        <a-input v-model="edit_User.photo"/>
      </a-form-item>
    </a-modal>

  </a-layout>

</template>

<script>
// const treeData = [
//   {
//     title: '部门一',
//     key: '0',
//     children: [
//       {
//         title: '部门一一',
//         key: '00',
//         children: [
//           {title: '部门一一一', key: '001'},
//           {title: '部门一一二', key: '002'},
//           {title: '部门一一三', key: '003'},
//         ],
//       },
//       {
//         title: '部门一二',
//         key: '01',
//         children: [
//           {title: '部门一二一', key: '011'},
//           {title: '部门一二二', key: '012'},
//           {title: '部门一二三', key: '013'},
//         ],
//       },
//     ],
//   },
// ];
import moment from "moment";

const columns = [
  {
    title: "id",
    dataIndex: "uid",
    width: "5%",
    scopedSlots: {customRender: "uid"}
  },
  {
    title: "用户名",
    dataIndex: "login_name",
    width: "15%",
    scopedSlots: {customRender: "login_name"}
  },
  {
    title: "名字",
    dataIndex: "name",
    width: "15%",
    scopedSlots: {customRender: "name"}
  },

  {
    title: "角色",
    dataIndex: "tags",
    scopedSlots: {customRender: "tags"}
  },
  {
    title: "最后登录时间",
    dataIndex: "updated_at",
    width: "15%",
    scopedSlots: {customRender: "updated_at"}
  },

  // {
  //   title: "状态",
  //   width: "5%",
  //   dataIndex: "status",
  //   scopedSlots: {customRender: "status"}
  // },
  {
    title: '操作',
    width: "10%",
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];

import {Modal} from "ant-design-vue";
import Storage from "../common/storage";

// import * as Util from "../common/util";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      columns,
      selectedKeys: ['1'],
      expandedKeys: ['1'],
      expandedaddKeys: ['1'],
      data: [],
      treeData: [],
      SectionuserData: [],
      pagination: {
        pageSize: 10
      },
      Section_id: "",
      Section_pid: "",
      Section_name: "",
      Section_show: false,
      loading: false,
      pop_Sectionadd: false,
      pop_Sectionedit: false,
      pop_Sectionchildrenadd: false,
      pop_Useradd: false,
      pop_Useredit: false,
      add_Section: {
        p_id: "",
        type: "",
        name: "",
        admin_uid: "",
      },
      edit_Section: {
        p_id: "",
        type: "",
        name: "",
        admin_uid: "",
      },
      add_User: {
        login_name: "",
        name: "",
        mobile: "",
        sex: "",
        email: "",
        photo: "",
        account_type: 1,
        rule_group: [],
        account_groups: [],
      },
      edit_User: {
        login_name: "",
        name: "",
        mobile: "",
        sex: "",
        email: "",
        photo: "",
        account_type: 1,
        rule_group: [],
        account_groups: [],
      },

    };
  },
  created() {
    // 获取第一页内容
    this.getSectionlist(1, 20, 1);

    // Promise.all([this.getitem(), this.getresource()]).then(() => {
    //   this.loading = false;
    // }).catch((error) => {
    //   console.log(error)
    // })
  },
  methods: {
    onContextMenuClick(event, e) {
      console.log('event', event);
      console.log('event.length', event.length);
      console.log('e', e);

      if (event.length === 0) {
        this.Section_show = false;
        return;
      }
      if (e.selectedNodes === []) {
        this.Section_show = false;

      } else {
        console.log('length', e.selectedNodes[0].data.props.dataRef);
        this.Section_id = event[0]
        this.Section_name = e.selectedNodes[0].data.props.dataRef.title
        this.Section_pid = e.selectedNodes[0].data.props.dataRef.p_id
        console.log('Section_id', this.Section_id);
        console.log('Section_pid', this.Section_pid);
        console.log('Section_name', this.Section_name);
        if (this.Section_pid !== null) {
          this.getSectionuserlist(1, 20, 1)
          this.Section_show = true;
        }
      }
    },


    start() {
      this.loading = true;
      this.data = [];
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
      this.doSearch(1, this.pagination.pageSize);
    },

    async onSearch(text) {
      this.data = []
      this.search_text = text;
      this.doSearch(1, this.pagination.pageSize, text);
    },

    async onChange(pagination) {
      await this.doSearch(
          pagination.current,
          pagination.pageSize
      );
    },

    async onChanges(e) {
      console.log(e.target.value);
    },

    addSection() {
      console.log("点击");
      this.pop_Sectionadd = true;
    },
    addSectionchildren() {
      console.log("点击");
      this.add_Section.name = "";
      this.pop_Sectionchildrenadd = true;
    },
    editSection() {
      console.log("点击");
      this.edit_Section.name = this.Section_name;
      this.pop_Sectionedit = true;
    },

    addadmin() {
      console.log("点击");
      this.pop_Useradd = true;
    },
    editadmin(item) {
      console.log("点击", item);
      this.edit_User.uid = item.uid;
      this.edit_User.login_name = item.login_name;
      this.edit_User.name = item.name;
      this.edit_User.mobile = item.mobile;
      this.edit_User.sex = item.sex;
      this.edit_User.email = item.email;
      this.edit_User.photo = item.photo;
      this.edit_User.account_type = item.account_type;
      this.edit_User.rule_group = item.rule_group;
      this.edit_User.account_groups = item.account_groups;
      this.pop_Useredit = true;
    },


    async Section_addok() {
      if (this.add_Section.name === "") {
        Modal.warning({
          title: "请输入职位名"
        });
        this.pop_Sectionadd = true;
        return
      }

      let now = Date.now();
      let result = await this.$post("/api/admin/accountGroups/create", {
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          p_id: this.add_Section.p_id,
          type: 1,
          name: this.add_Section.name,
          admin_uid: this.add_Section.admin_uid,
          t: now,
        },

      });

      if (result.status === true) {
        this.data = []
        await this.getSectionlist(1, 20, 1);
        Modal.info({
          title: "更新成功"
        });
      }
      this.pop_Sectionadd = false;
    },
    async User_addok() {
      if (this.add_User.name === "") {
        Modal.warning({
          title: "请输入职位名"
        });
        this.pop_Useradd = true;
        return
      }

      let now = Date.now();
      let result = await this.$post("/api/admin/account/create", {
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          login_name: this.add_User.login_name,
          name: this.add_User.name,
          mobile: this.add_User.mobile,
          sex: this.add_User.sex,
          email: this.add_User.email,
          photo: this.add_User.photo,
          account_type: this.add_User.account_type,
          rule_group: this.add_User.rule_group,
          account_groups: [this.Section_id],
          t: now,
        },

      });

      if (result.status === true) {
        Modal.info({
          title: "请记录好密码",
          content: result.data.password,
          onOk() {
            console.log('OK');
          },
        });
        await this.getSectionlist(1, 20, 1);

      }
      this.pop_Useradd = false;
    },
    async User_editok() {
      if (this.edit_User.name === "") {
        Modal.warning({
          title: "请输入职位名"
        });
        this.pop_Useradd = true;
        return
      }

      let now = Date.now();
      let result = await this.$post("/api/admin/account/update", {
        uid: Storage.uid,
        token: Storage.token,
        edit_uid: this.edit_User.uid,
        save_value: {
          login_name: this.edit_User.login_name,
          name: this.edit_User.name,
          mobile: this.edit_User.mobile,
          sex: this.edit_User.sex,
          email: this.edit_User.email,
          photo: this.edit_User.photo,
          account_type: this.edit_User.account_type,
          rule_group: this.edit_User.rule_group,
          account_groups: [this.Section_id],
          t: now,
        },

      });

      if (result.status === true) {
        this.data = []
        await this.getSectionlist(1, 20, 1);
        Modal.info({
          title: "更新成功"
        });
      }
      this.pop_Useredit = false;
    },
    async Section_childrenadd() {
      if (this.add_Section.name === "") {
        Modal.warning({
          title: "请输入职位名"
        });
        this.pop_Sectionchildrenadd = true;
        return
      }

      let now = Date.now();
      let result = await this.$post("/api/admin/accountGroups/create", {
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          p_id: this.Section_id,
          type: 1,
          name: this.add_Section.name,
          admin_uid: this.add_Section.admin_uid,
          t: now,
        },

      });

      if (result.status === true) {
        this.data = []
        await this.getSectionlist(1, 20, 1);
        Modal.info({
          title: "更新成功"
        });
      }
      this.pop_Sectionchildrenadd = false;
    },

    async Sectionuser_add() {
      if (this.add_Section.name === "") {
        Modal.warning({
          title: "请输入职位名"
        });
        this.pop_Sectionchildrenadd = true;
        return
      }

      let now = Date.now();
      let result = await this.$post("/api/admin/account/create", {
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          login_name: this.add_User.login_name,
          name: this.add_User.name,
          mobile: this.add_User.mobile,
          sex: this.add_User.sex,
          email: this.add_User.email,
          photo: this.add_User.photo,
          account_type: 1,
          rule_group: this.add_User.rule_group,
          account_groups: this.Section_id,
          t: now,
        },

      });

      if (result.status === true) {
        this.data = []
        await this.getSectionlist(1, 20, 1);
        Modal.info({
          title: "更新成功"
        });
      }
      this.pop_Sectionchildrenadd = false;
    },


    async getSectionlist(page, limit, type) {
      this.loading = true;
      let result = await this.$get("/api/admin/accountGroups/list", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          page: page,
          limit: limit,
          type: type,
        }
      });

      if (result.status === true) {
        let data = [];
        console.log(result.data)
        for (let i in result.data) {
          let info = result.data[i];

          data[i] = {
            title: info.name,
            key: info.id,
            children: info.child,
          };
        }
        this.treeData = data;

        this.loading = false;
      }

      this.loading = false;
    },
    async getSectionuserlist(page, limit, type) {
      this.loading = true;
      this.SectionuserData = [];
      let result = await this.$post("/api/admin/account/getAdmins", {
        uid: Storage.uid,
        token: Storage.token,
        page: page,
        limit: limit,
        account_type: type,
        account_group_info: {
          account_groups_id: this.Section_id,
          is_in: 1,
        }
      });

      if (result.status === true) {
        let data = [];
        console.log(result.data.list)
        for (let i in result.data.list) {
          let info = result.data.list[i];

          data[i] = {
            uid: info.uid,
            created_at: moment(info.created_at).format("YYYY/MM/DD hh:mm:ss"),
            updated_at: moment(info.updated_at).format("YYYY/MM/DD hh:mm:ss"),
            name: info.name,
            login_name: info.login_name,
            is_admin: info.is_admin,
            is_check: info.is_check,
            is_student: info.is_student,
            is_teacher: info.is_teacher,
            level: info.level,
            email: info.email,
            mobile: info.mobile,
            sex: info.sex,
            status: info.status,

          };
        }
        this.SectionuserData = data;

        this.loading = false;
      }

      this.loading = false;
    },

    async Section_editok() {
      if (this.edit_Section.name === "") {
        Modal.warning({
          title: "请输入部门名字"
        });
        return;
      }


      let result = await this.$post("/api/admin/accountGroups/update", {
        uid: Storage.uid,
        token: Storage.token,
        account_group_id: this.Section_id,
        save_value: {
          name: this.edit_Section.name,
        },
      });

      if (result.status === true) {
        this.data = []
        await this.getSectionlist(1, 20, 1);
        Modal.info({
          title: "修改成功"
        });
      }
      this.Section_name = this.edit_Section.name;
      this.pop_Sectionedit = false;
    },


    async delSection(item) {
      var that = this;
      console.log(item);
      Modal.confirm({
        title: "警告！",
        content: "是否确认剔除该部门?",

        onOk: async function () {

          let result = await that.$post("/api/admin/accountGroups/delete", {

            uid: Storage.uid,
            token: Storage.token,
            account_group_id: item,
            // notes: this.edit_pop.notes,
            t: Date.now(),
            // sign: Crypt.sign([
            //     Storage.token,
            //     this.edit_pop.id,
            //     JSON.stringify(data),
            //     this.edit_pop.notes,
            //     this.$config.secret_key,
            //     now
            // ])
          });
          console.log(result);
          if (result.status === true) {
            console.log("准备弹窗！");
            await that.getSectionlist(1, 20, 1);
            that.Section_show = false;
            Modal.info({
              title: "剔除成功"
            });
          }
          if (result.status === false) {
            // that.data = []
            // await this.doSearch(this.page, "1", "", this.estatedefault, "");
            Modal.info({
              title: result.msg
            });

          }
        }

      });


    },
    async deladmin(item) {
      var that = this;
      console.log(item);
      Modal.confirm({
        title: "警告！",
        content: "是否确认剔除该用户?",

        onOk: async function () {

          let result = await that.$post("/api/admin/account/delete", {

            uid: Storage.uid,
            token: Storage.token,
            edit_uid: item.uid,
            // notes: this.edit_pop.notes,
            t: Date.now(),
            // sign: Crypt.sign([
            //     Storage.token,
            //     this.edit_pop.id,
            //     JSON.stringify(data),
            //     this.edit_pop.notes,
            //     this.$config.secret_key,
            //     now
            // ])
          });
          console.log(result);
          if (result.status === true) {
            console.log("准备弹窗！");
            await that.getSectionlist(1, 20, 1);
            that.Section_show = false;
            Modal.info({
              title: "剔除成功"
            });
          }
          if (result.status === false) {
            // that.data = []
            // await this.doSearch(this.page, "1", "", this.estatedefault, "");
            Modal.info({
              title: result.msg
            });
          }
        }

      });


    },
  }
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.serach_input {
  width: 25vw;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 25px;
}

.pages {
  float: right;
  margin-top: 10px;
}

.toolsbar {
  display: block;
  width: 100%;
  height: 42px;
  background: #fff
}
</style>
